<template>

  <div>

    <b-card>
      <h2>
        Informacje o ofercie
      </h2>
      <!----app will auto inject-->
      <span v-html="offer.description" />
    </b-card>

    <b-card>
      <h2>
        tagi:
      </h2>
      <br>
      <span
        v-for="tag in offer.tags"
        :key="tag"
        style="background-color: purple; color: #fff; padding: 0.4rem 0.5rem; margin: 0.2rem 0.5rem; border-radius: 0.5rem;"
      >
        {{ tag.name }}
      </span>
    </b-card>

    <b-card>
      <h2>
        Pracownicy:
      </h2>
      <br>
      <div
        v-for="employee in offer.employees"
        :key="employee"
        class="d-flex align-items-center justify-content-between"
      >
        <div>
          {{ employee.description }}
        </div>

        <div>
          <router-link :to="{name: 'Employee-show', params: {id: employee.id}}">
            <b-button
              variant="primary"
              size="sm"
              @click="removeEmployee(employee)"
            >
              Więcej informacji
            </b-button>
          </router-link>
          <hr>
        </div>
      </div>
    </b-card>

    <b-card>
      <h2>
        Sprzęty:
      </h2>
      <div>
        <span
          v-for="equipment in offer.equipment"
          :key="equipment"
        >
          <router-link :to="{name: 'Equipment-show', params: {id: equipment.info.id }}">
            <div style="text-align:center;">
              {{ equipment.info.name }} | {{ equipment.info.quantity }} | {{ equipment.info.id }}
              <hr>
            </div>
          </router-link>
        </span>
        <div v-if="!offer.equipment[0]">
          <div style="text-align:center; font-size: 1.5em;">
            Brak sprzętu
            <router-link :to="{name: 'Equipment-add'}">
              Dodaj sprzęt
            </router-link>
          </div>
        </div>
      </div>
    </b-card>

    <b-card>
      <h2>
        Promocje:
      </h2>
      <div v-if="!offer.promotion[0]">
        <div style="text-align:center; font-size: 1.5em;">
          Usługa nie jest na promocji...
          <router-link :to="{name: 'Promotions-add'}">
            Dodaj promocję
          </router-link>
        </div>
      </div>
      <hr>
      <div
        v-for="promotion in offer.promotion"
        :key="promotion"
      >
        <div style="display:flex; justify-content:space-between; align-items: center;">
          <span>
            <span v-if="!offer.promotion.price"> Usługa nie posiadała ceny </span> -> {{ promotion.new_price }}
          </span>
          <button
            class="btn btn-primary"
            style="padding: 10px;"
          >
            <router-link
              :to="{name: 'Promotions-edit', params: {id: promotion.id }}"
              style="color: white;"
            >
              Edytuj
            </router-link>
          </button>
        </div>
        <hr>
      </div>
    </b-card>

    <b-card>
      <router-link
        :to="`${$route.params.id}/edytuj`"
        class="btn btn-primary w-100"
        style="margin-bottom: 10px;"
      >
        Edytuj dane usługi
      </router-link>

      <button
        class="btn btn-danger"
        style="width:100%; margin-bottom: 10px;"
        @click="deleteOffer"
      >
        Usuń usługę
      </button>
      <router-link to="/oferty">
        <button
          class="btn btn-primary"
          style="width:100%;"
        >
          Powrót
        </button>
      </router-link>
    </b-card>

  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import {
  BCard, BButton,
} from 'bootstrap-vue'

export default {
  name: 'SingleOffer',
  components: {
    BCard,
    BButton,
  },
  data() {
    return {
      form: {
        week_day: '',
        employee_id: '',
        time: '',
      },
      dates: [],
      employees: [],
      offer: [],
    }
  },
  mounted() {
    document.title = `Usługa | ${this.$route.params.id} - Pickmode`

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }

    axios.get(`${process.env.VUE_APP_API_URL}employee`, config)
      .then(response => {
        this.employees = response.data.success
      })
      .catch(error => {
        swal.fire({
          title: 'Error',
          text: error.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok',
        })
      })

    axios.get(`${process.env.VUE_APP_API_URL}Offer/${this.$route.params.id}`, config)
      .then(response => {
        this.offer = response.data.offer
      })
      .catch(error => {
        if (error.response.status === 404 || error.response.status === 401) {
          this.$router.push('/error-404')
        }
      })
  },
  methods: {
    Adddate() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.post(`${process.env.VUE_APP_API_URL}Add-offer-date/${this.$route.params.id}`, {
        employee_id: this.form.employee_id,
        week_day: this.form.week_day,
        time: this.form.time,
      }, config)
        .then(response => {
          swal.fire({
            title: 'Success',
            text: response.data.message,
            icon: 'success',
            confirmButtonText: 'Ok',
          })
        })
        .catch(error => {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
    Show_new_date_form() {
      this.form.employee_id = ''
      this.form.week_day = ''
      this.form.time = ''
      $('#new_date_form').modal('show')
    },
    deleteOffer() {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }
      axios.delete(`${process.env.VUE_APP_API_URL}Offer/${this.$route.params.id}`, config)
        .then(response => {
          swal.fire({
            title: 'Success',
            text: response.data.message,
            icon: 'success',
            confirmButtonText: 'Ok',
          })
          this.$router.push('/oferty')
        })
        .catch(error => {
          swal.fire({
            title: 'Error',
            text: error.response.data.message,
            icon: 'error',
            confirmButtonText: 'Ok',
          })
        })
    },
  },
}
</script>

<style>

</style>
